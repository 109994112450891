@use '../utils' as *;

/*----------------------------------------*/
/*  02. HEADER CSS START
/*----------------------------------------*/

.header {
    &__area {
        @media #{$xxl} {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media #{$xl} {
            padding: 0 20px;
        }

        @media #{$lg,$md} {
            padding: 0px;
        }

        @media #{$sm} {
            padding: 20px 30px;
        }

        @media #{$xs} {
            padding: 0px 0px;
            height: 35px !important;
        }
    }

    &__transparent {
        position: absolute;
        left: 0;
        margin: auto;
        top: 0;
        width: 100%;
        z-index: 99;
        background: rgba(234, 167, 167, .36) !important;
    }

    &__border {
        border-bottom: 1px solid rgba($color: #D9D9D9, $alpha: .4);
    }

    &__sticky {
        &.header__sticky {
            position: fixed !important;
            left: 0;
            margin: auto;
            top: 0;
            width: 100%;
            box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
            z-index: 99;
            -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
            animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
            -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
            // background: var(--tp-common-white);
        }
    }
}



/* main menu css */

.hover-text-black:hover {
    color: black !important;
}


.normal-menu,
.main-menu {
    ul {
        li {
            position: relative;
            list-style: none;
            display: inline-block;
            /* Ensure horizontal layout by default */
            margin: 0 35px;

            /* Spacing between menu items */


            /* Mobile: Stacks vertically */
            @media (max-width: 576px) {
                margin-bottom: 30px;
                display: block;
                /* Stack vertically */
            }

            /* Larger screens (XXL, XL, etc.) */
            @media (min-width: 1600px) {
                margin: 0 20px;
                /* Adjust spacing for extra large screens */
            }

            @media (min-width: 1400px) {
                margin: 0 18px;
            }

            @media (min-width: 1200px) {
                margin: 0 15px;
            }

            a {
                line-height: 30px;
                letter-spacing: 0.15em;
                display: inline-block;
                font-size: 15px;
                color: #fff;
                padding: 50px 0;
                font-weight: 400;
                font-family: sans-serif;
                text-transform: uppercase;

                /* Adjust letter spacing for XXL */
                @media (min-width: 1200px) {
                    letter-spacing: 0.10em;
                }
            }

            &.has-dropdown {
                >a {
                    position: relative;

                    &::after {
                        content: '\f107';
                        font-size: 15px;
                        color: #fff;
                        font-family: 'FontAwesome';
                        font-weight: 400;
                        margin-left: 5px;
                        display: inline-block;
                        transition: transform 0.3s ease;
                    }
                }
            }

            .submenu {
                text-align: left;
                position: absolute;
                top: 120%;
                left: 0;
                width: 260px;
                padding: 32px 0 30px;
                background: #000;
                z-index: 99;
                transition: all 0.3s ease;
                visibility: hidden;
                opacity: 0;
                box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);

                li {
                    display: block;
                    width: 100%;
                    margin: 0;
                    margin-bottom: 7px;

                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    }

                    &.has-dropdown>a::after {
                        position: absolute;
                        top: 50%;
                        right: 25px;
                        transform: translateY(-50%) rotate(-90deg);
                    }

                    a {
                        padding: 0px 40px;
                        font-size: 13px;
                        position: relative;
                        color: #fff;
                        width: 100%;
                        letter-spacing: 0.18em;

                        &::before {
                            position: absolute;
                            content: '';
                            top: 0;
                            right: 0;
                            width: 0;
                            height: 100%;
                            background-color: #ff7f50;
                            /* Example theme color */
                            z-index: -1;
                            transition: width 0.3s ease;
                        }
                    }

                    .submenu {
                        left: 120%;
                        top: 0;
                        visibility: hidden;
                        opacity: 0;
                    }

                    &:hover {
                        a {
                            color: #fff;

                            &::before {
                                width: 100%;
                                left: 0;
                            }

                            &::after {
                                color: #fff;
                            }
                        }

                        .submenu {
                            left: 100%;
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }

            &:hover {
                a::after {
                    transform: rotate(-180deg);
                }

                .submenu {
                    top: 100%;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}



.normal-menu {
    & ul {
        & li {
            & a {
                color: var(--tp-common-black);
            }

            &.has-dropdown {
                &>a {
                    position: relative;

                    &::after {
                        content: '\f107';
                        @include transform(translateY(1px));
                        font-size: 15px;
                        color: inherit;
                        font-family: var(--tp-ff-fontawesome);
                        font-weight: var(--tp-fw-normal);
                        margin-left: 5px;
                        display: inline-block;
                    }
                }
            }

            &:hover {
                &>a {
                    &::after {
                        transform: rotate(-180deg);
                    }
                }

                &>.submenu {
                    top: 100%;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}

.header__main_right {
    font-size: 18px;
}

/* Default styles for tablet and larger screens */
@media (min-width: 768px) and (max-width: 991.98px) {
    .translate-wrapper {
        position: absolute;
        top: 0;
        /* Align it at the top */
        right: 0;
        /* Align it to the right */
        margin-right: 20px;
        /* Add a margin if needed */
        padding: 10px;
        z-index: 1000;
        /* Ensure it's on top of other content */
        text-align: right;
        /* Align text to the right */
    }
}

/* For mobile (below 768px) */
@media (max-width: 767.98px) {
    .translate-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        // padding: 10px;
        z-index: 1000;
        text-align: center;
        /* Center-align the content for mobile */
    }
}

/* For larger screens (above 992px) */
@media (min-width: 992px) {
    .translate-wrapper {
        position: relative;
        margin-right: 80px;
        /* Keep it on the right with some margin */
        text-align: right;
        /* Align text to the right */
    }
}





.tp-bar-icon {
    color: var(--tp-common-white);
    font-size: 40px;

    @media #{$sm ,$xs} {
        font-size: 30px;
        position: absolute;
        top: 0px;
        /* Adjust as needed */
        right: 10px;
        /* Position the button on the right */
        z-index: 1000;
    }
}

.tp-bar-icon-dark {
    color: var(--tp-common-black);
}


.offcanvas__area {
    position: fixed;
    top: 0;
    right: -130%;
    width: 33%;
    height: 100%;
    padding: 40px;
    @include transition(1s);
    z-index: 999;
    min-width: 300px;
    min-height: 768px;
    overflow-y: scroll;
}

.offcanvas-subtitle {
    text-transform: uppercase;
    padding: 20px 0;

    @media #{$xs,$md,$lg} {
        display: none;
    }
}

.offcanvas_area-logo {
    margin: 30px 0;
}

.offcanva-details {
    @media #{$xs,$md,$lg} {
        display: none;
    }
}

.offcanvas__area.opened {
    right: 0;
}

.ct-position {
    @media #{$xs,$md,$lg} {
        display: none;
    }
}

.ct-position span::before {
    top: 22%;
}

.offcanvas-ct-info {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 40px;

    @media #{$xs,$md,$lg} {
        position: static;
        padding: 40px 0;
    }
}

.offcanva-btn {
    @media #{$lg,$md,$xs} {
        display: none;
    }
}

.tp-mobile-menu.mean-container {
    overflow: hidden;
    clear: both;
}


.header-sticky-active.header-sticky {
    position: fixed;
    left: 0;
    margin: auto;
    top: 0;
    width: 100%;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
    z-index: 99;
    -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    // background: var(--tp-common-white);
    padding-right: 100px;
    padding-left: 100px;

    & ul {
        & li {
            a {
                padding-top: 35px;
                padding-bottom: 35px;
            }
        }
    }

    @media #{$xxl} {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media #{$xl} {
        padding: 0 20px;
    }

    @media #{$md,$lg} {
        padding: 30px;
    }

    @media #{$xs} {
        padding: 30px 0;
    }


}

.header__transparent.header-sticky {
    background-color: #D9BABA !important;
    padding-right: 90px;
    padding-left: 90px;

    @media #{$xxl} {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media #{$xl} {
        padding-right: 20px;
        padding-left: 20px;
    }

    @media #{$md} {
        padding: 0px;
    }

    @media #{$xs} {
        padding: 0px 0;
    }
}