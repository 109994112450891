@forward 'components/theme';
@forward '../css/font-awesome-pro.css';
@forward '../css/animate.css';
@forward '../css/ui-icon.css';
@forward '../css/pe-icon-7-stroke.css';
@forward 'components/back-to-top';
@forward 'components/background';
@forward 'components/spacing';
@forward 'components/buttons';
@forward 'components/carousel';
@forward 'components/modal';
@forward 'components/section-title';
@forward 'components/breadcrumb';
@forward 'components/animation';
@forward 'components/accordion';
@forward 'components/preloader';
@forward 'layout/header';
@forward 'layout/meanmenu';
@forward 'layout/slider';
@forward 'layout/service';
@forward 'layout/about';
@forward 'layout/gallery';
@forward 'layout/team';
@forward 'layout/feature';
@forward 'layout/testimonial';
@forward 'layout/portfolio';
@forward 'layout/promotion';
@forward 'layout/project';
@forward 'layout/price';
@forward 'layout/blog';
@forward 'layout/contact';
@forward 'layout/footer';


//css for google translate widget
.VIpgJd-ZVi9od-ORHb-OEVmcd {
 display: none !important;
}

body {
 top: 0 !important;
}

.goog-te-gadget img {
 display: none;
}

.goog-te-gadget-simple {
 background-color: transparent;
 padding: 0;
 border: 0;
}

.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed {
 color: white;
}

.goog-te-balloon-frame {
 display: none !important;
}

.goog-te-banner-frame.skiptranslate {
 display: none !important;
}

#goog-gt-tt {
 display: none !important;
}